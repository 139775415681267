.App {
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  background-color: #222;
  color: white;
}

.logoAndButtonsContainer {
  padding: 22px 10px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  background-color: #222;
  height: 30px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  background-color: #222;
  margin: 8px;
}

.buttons {
  width: 200px;
  background-color:#222;
  margin: 8px;
  text-align: center;
}

.buttons:hover {
  color: #E500FF;
  cursor: pointer;
}

.topLogo {
  width: 150px;
  height: auto;
  margin: 8px;
}

.topLogo:hover {
  cursor: pointer;
}

.mainContainer {
  text-align: left;
  height: 81vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../public/ubastibackgroundgrey.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.mainParagragh {
  width: 66vw;
  margin: 8px;
  font-size: 2vh;
}

.companyName {
  font-weight: bold;
  font-size: 2vh;
  color: #E500FF;
}

.firstLine {
  font-size: 2vh;
}

.enquiryButton {
  background-color: #E500FF;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 8px;
  width: 150px;
  font-size: 1.9vh;
  margin: 8px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  display: felx;
  justify-content: center;
  align-items: center;
}

.enquiryButton:hover {
  background-color: white;
  color: #E500FF;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 8px;
  width: 150px;
  font-size: 1.9vh;
  margin: 8px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
} 

.footerContainer {
  background-color: #222;
  position: fixed;
  bottom: 0;
  width: 100%;
  color: white;
}

.socialLogos {
  color: white;
}
.socials{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
